import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import useDataContext from '../App/Context';
import useVerifySession from '../../Hooks/useVerifySession/useVerifySession';
import { HttpError, request } from '../../Utils/Utils';
import Input from '../Form/Input';
import Toggle from '../Form/Toggle';
import Loader from '../Loader/Loader';

/**
 * Data reducer
 * @param {Object} data
 * @param {Object} action
 * @return {Object|null}
 */

function reducer(data, action) {
	switch(action.type) {
		case 'set':
			data[action.property] = action.value || null;
			break;
		default:
			return { ...data, ...action };
	}
}

/**
 * Login component
 * @return {JSX.Element}
 * @constructor
 */

function Login({ className }) {
	const navigate = useNavigate();
	const { data, setUserData } = useDataContext();
	const [ status, setStatus ] = useReducer(reducer, { loading : false, responseText : '', responseType : null });
	const formCallback = async (e) => {
		e.preventDefault();
		setStatus({ loading : true, responseText : '', responseType : null });
		const data = new FormData(e.target);
		try {
			await request(`${process.env.REACT_APP_API_BASE_URL}/api/login.json`, {
				method      : 'POST',
				credentials : 'include',
				body        : data
			})
			.then(response => response.json())
			.then(json => {
				if(json.hasOwnProperty('token') && json?.token !== null) {
					setUserData({ token : json.token, name : json.name, iframe_url : json.iframe_url });
					navigate('/dashboard');
				} else {
					setStatus({ loading : false, responseText : 'Ocurrió un error al procesar la solicitud. Por favor inténtelo nuevamente más tarde o contacte al administrador.', responseType : 'error' });
				}
			});
		} catch(error) {
			if(error instanceof HttpError) {
				switch(error.cause.status) {
					case 401:
						setStatus({ loading : false, responseText : 'Usuario y/o contraseña incorrectos.', responseType : 'error' });
						break;
					default:
						setStatus({ loading : false, responseText : 'Ocurrió un error al procesar la solicitud. Por favor inténtelo nuevamente más tarde o contacte al administrador.', responseType : 'error' });
						break;
				}
			}
		}
	};
	const [ verifySession, setVerifySession ] = useVerifySession(
		data?.token,
		data => {
			setVerifySession(false);
			setUserData({ token : data.token, name : data.name, iframe_url : data.iframe_url });
			navigate('/dashboard');
		},
		() => {
			setVerifySession(false);
		}
	);
	return (
		<>
			{verifySession === true && <Loader text="Verificando sesión..." />}
			<form className={`row form ${className}`} onSubmit={formCallback}>
				{status?.responseType !== null && <div className={`form__message mb-2 form__message--${status?.responseType}`}>{status?.responseText}</div>}
				<div className="mb-2">
					<Input label="Email" type="email" name="email" id="email" disabled={status?.loading === true} />
				</div>
				<div className="mb-2">
					<Input label="Contraseña" type="password" name="password" id="password" disabled={status?.loading === true} />
				</div>
				<div className="mb-2">
					<Toggle label="Recordarme en este equipo" value="1" id="remember" name="remember" disabled={status?.loading === true} />
				</div>
				<div>
					<div className="form__button-container">
						<button className="form__button" type="submit" disabled={status?.loading === true}>Ingresar al reporte</button>
						{status?.loading === true && <Loader type="absolute" text="Autenticando..." />}
					</div>
				</div>
			</form>
		</>
	);
}

export default Login;