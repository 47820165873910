import React, { useState, forwardRef } from 'react';

/**
 * Input component
 * @param {string}   label
 * @param {string}   initialValue
 * @param {string}   type
 * @param {string}   name
 * @param {string}   id
 * @param {function} onChamge
 * @return {JSX.Element}
 * @constructor
 */

const Input = forwardRef(({ label = '', initialValue = '', type = 'text', name = 'input', id = 'input', disabled = false, onChange = null }, ref) => {
	const [value, setValue] = useState(String(initialValue));
	const classes = ['form__input'];
	const properties = {};
	disabled === true && classes.push('form__input--disabled') && (properties['disabled'] = true);
	const onChangeCallback = e => {
		const value = e.currentTarget.value;
		setValue(value);
		onChange !== null && onChange(value, e);
	}
	return (
		<>
			<label htmlFor={id} className="form__label">{label}</label>
			<input type={type} value={value} name={name} id={id} onChange={onChangeCallback} className={classes.join(' ')} ref={ref} {...properties} />
		</>
	)
});

export default Input;