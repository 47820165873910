import React from "react";
import { NavLink } from 'react-router-dom';
import Base from '../Base/Base';
import LoginForm from '../../Components/Login/Login';

/**
 * Login component
 * @return {JSX.Element}
 * @constructor
 */

function Login() {
	return (
		<Base>
			<h1>Te damos la bienvenida
				<br/>a BI Harvest</h1>
			<p className="mb-3">Ingresa tus credenciales para acceder al reporte.</p>
			<LoginForm className="mb-2" />
			<p className="text-center"><NavLink to="/recuperar-contrasena" className="link__small">Olvidé mi contraseña</NavLink></p>
		</Base>
	);
}

export default Login;