import React from 'react';

/**
 * Footer component
 * @return {JSX.Element}
 * @constructor
 */

function Footer() {
	return (
		<footer className="footer app__element app__element--shrink p-2">
			<p className="footer__text">&copy; <strong>Copyright BI Harvest</strong> | Todos los derechos reservados</p>
		</footer>
	);
}

export default Footer;