import { createContext, useContext, useReducer } from 'react';

// Context definition
const Context = createContext({});
Context.displayName = 'AppContext';

// Context usage helper
const useDataContext = () => useContext(Context);
export default useDataContext;

/**
 * Date reducer funcion
 * @param {*}      state
 * @param {Object} action
 * @return {*}
 */

function dataReducer(data, action) {
	let result = { ...data };
	switch(action?.type) {
		case 'set':
			result[action.property] = action.value || '';
			break;
		default:
			result = { ...data, ...action };
			break;
	}
	return result;
}

/**
 * ContextProvider component
 * @param {Object}      initial
 * @param {JSX.Element} children
 * @return {JSX.Element}
 * @constructor
 */

function ContextProvider({ initial = { token : null, name : '', iframe_url : null }, children }) {
	const [ data, setData ] = useReducer(dataReducer, {
		token      : initial?.token || null,
		name       : initial?.name || '',
		iframe_url : initial?.iframe_url || null
	});
	function setUserData(data) {
		setData(data);
	}
	return (
		<Context.Provider value={{ data, setUserData }}>
			{children}
		</Context.Provider>
	);
}

export { ContextProvider };