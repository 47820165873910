import React from 'react';

import image from '../../Assets/Images/splash-bg.jpg';

/**
 * Base component
 * @param children
 * @return {JSX.Element}
 * @constructor
 */

function Base({ children }) {
	return (
		<section className="row h-md-100 form-layout">
			<div className="col col--size-md-60 col--order-md-2 p-md-2 mb-2 mb-md-0">
				<div className="bg-image" style={{ backgroundImage : `url(${image})` }}></div>
			</div>
			<div className="col col--size-md-40 col--order-md-1 p-2">
				<div className="row row--align-content-center h-100">
					<div className="col">
						<div className="logotype">BI Harvest</div>
						{children}
					</div>
				</div>
			</div>
		</section>
	);
}

export default Base;