import { useEffect, useState } from 'react';
import { request } from '../../Utils/Utils';

/**
 * useVerifySession hook
 * @param {boolean|null} initial
 * @param {function}     success
 * @param {function}     fallback
 * @return {(boolean|((value: (((prevState: boolean) => boolean) | boolean)) => void))[]}
 */

function useVerifySession(initial = null, success = data => {}, fallback = () => {}) {
	const [ verifySession, setVerifySession ] = useState(initial === null);
	useEffect(() => {
		if(verifySession === true) { // initial === null
			try {
				request(`${process.env.REACT_APP_API_BASE_URL}/api/verify-session.json`, {
					method      : 'POST',
					credentials : 'include'
				})
				.then(response => response.json())
				.then(json => {
					if(json.hasOwnProperty('token') && json?.token !== null) success(json);
					else fallback();
				})
				.catch(() => {
					fallback();
				});
			} catch(e) {
				fallback();
			}
		}
	});
	return [verifySession, setVerifySession];
}

export default useVerifySession;