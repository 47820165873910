import React, { useState } from 'react';

/**
 * Toggle component
 * @param {string}   label
 * @param {boolean}  checked
 * @param {string}   value
 * @param {string}   id
 * @param {string}   name
 * @param {function} onChamge
 * @return {JSX.Element}
 * @constructor
 */

const Toggle = function({ label = false, checked = false, value = '', id = 'toggle', name = 'toggle', disabled = false, onChange = null }) {
	const [selected, setSelected] = useState(checked);
	const classes = ['toggle'];
	const properties = {};
	selected === true && classes.push('toggle--checked');
	disabled === true && classes.push('toggle--disabled') && (properties['disabled'] = true);
	const onChangeCallback = e => {
		const value = e.currentTarget.checked;
		setSelected(value);
		onChange !== null && onChange(value, e);
	}
	return (
		<>
			<label htmlFor={id} className={classes.join(' ')}>
				<input type="checkbox" checked={selected} id={id} name={name} value={value} className="toggle__input" onChange={onChangeCallback} {...properties} />
				<label htmlFor={id} className="toggle__switch"></label>
			</label>
			{label !== false && <label htmlFor={id} className="toggle__label">{label}</label>}
		</>
	);
};

export default Toggle;