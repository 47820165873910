/**
 * Http error implementation
 * @class HttpError
 */

export class HttpError extends Error {
	constructor(response) {
		super(`HTTP error status ${response.status}`, {
			cause : {
				status : response.status
			}
		});
	}
}

/**
 * Request
 * @param {String} url
 * @param {Object} options
 * @return {Promise<any>}
 */

export async function request(url, options) {
	const result = await fetch(url, options);
	if(!result.ok) throw new HttpError(result);
	return result;
}