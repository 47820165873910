import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ContextProvider } from './Context';
import Loader from '../Loader/Loader';
import Login from '../../Layout/Login/Login';
import Footer from '../../Layout/Footer/Footer';

import '../../Assets/Styles/styles.scss';

/**
 * App component
 * @return {JSX.Element}
 * @constructor
 */

const Main = lazy(() => import('../../Layout/Main/Main'));
const Dashboard = lazy(() => import('../Dashboard/Dashboard'));
const ChangePassword = lazy(() => import('../ChangePassword/ChangePassword'));
const VerifyAccount = lazy(() => import('../../Layout/VerifyAccount/VerifyAccount'));
const PasswordRecovery = lazy(() => import('../../Layout/PasswordRecovery/PasswordRecovery'));
const RecoverPassword = lazy(() => import('../../Layout/RecoverPassword/RecoverPassword'));

function App() {
	return (
		<BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
			<div className="app app--full-height">
				<div className="app__element app__element--grow">
					<ContextProvider>
						<Routes>
							<Route index path="/" element={<Login />} />
							<Route element={<Suspense fallback={<Loader />}><Main /></Suspense>}>
								<Route index path="/dashboard" element={<Suspense fallback={<Loader />}><Dashboard /></Suspense>} />
								<Route index path="/cambiar-contrasena" element={<Suspense fallback={<Loader />}><ChangePassword /></Suspense>} />
							</Route>
							{/*<Route index path="/dashboard" element={<Suspense fallback={<Loader />}><Dashboard /></Suspense>} />*/}
							<Route index path="/verificar-cuenta" element={<Suspense fallback={<Loader />}><VerifyAccount /></Suspense>} />
							<Route index path="/recuperar-contrasena" element={<Suspense fallback={<Loader />}><PasswordRecovery /></Suspense>} />
							<Route index path="/recuperacion-contrasena" element={<Suspense fallback={<Loader />}><RecoverPassword /></Suspense>} />
							<Route index path="*" element={<Navigate to="/" />} />
						</Routes>
					</ContextProvider>
				</div>
				<Footer />
			</div>
		</BrowserRouter>
	);
}

export default App;